import React, { useState, Suspense } from 'react';
import './App.css';
import LazySection from './components/LazySection';
import Preloader from './components/Preloader/Preloader';

const Navigation = React.lazy(() => import('./components/Navigation/Navigation'));
const FirstSection = React.lazy(() => import('./components/MainSection/MainSection'));
const HomeCategories = React.lazy(() => import('./components/CategoriesHomePage/CategoriesHomePage'));
const HomeCatalog = React.lazy(() => import('./components/CatalogHomePage/HomeCatalog'));
const AboutSection = React.lazy(() => import('./components/TriggerHomePage/TriggerHomePage'));
const HomeSertificats = React.lazy(() => import('./components/SertificatsSection/Sertificats'));
const HomeAboutUs = React.lazy(() => import('./components/AboutUs/AboutUs'));
const CallbackForm = React.lazy(() => import('./components/CallbackSection/CallbackSection'));
const Footer = React.lazy(() => import('./components/FooterSection/FooterSection'));


function App() {
    const [isLoaded, setIsLoaded] = useState(false);

    const handleLoadComplete = () => {
        setIsLoaded(true);
    };

    return (
        <>
            {!isLoaded && <Preloader onLoadComplete={handleLoadComplete} />}
            {isLoaded && (
                <div className="App">
                    <Suspense fallback={<div>Loading navigation...</div>}>
                        <Navigation />
                    </Suspense>

                    <Suspense fallback={<div>Loading first section...</div>}>
                        <FirstSection />
                    </Suspense>

                    <LazySection>
                        <HomeCategories />
                    </LazySection>

                    <LazySection>
                        <HomeCatalog />
                    </LazySection>

                    <LazySection>
                        <AboutSection />
                    </LazySection>

                    <LazySection>
                        <HomeSertificats />
                    </LazySection>

                    <LazySection>
                        <HomeAboutUs />
                    </LazySection>

                    <LazySection>
                        <CallbackForm />
                    </LazySection>

                    <LazySection>
                        <Footer />
                    </LazySection>
                </div>
            )}
        </>
    );
}

export default App;
