import React, { useState, useEffect } from 'react';

const LazySection = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = React.useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Отключаем наблюдение после загрузки секции
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1, // 10% секции должно быть в области видимости
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return <div ref={sectionRef}>{isVisible && children}</div>;
};

export default LazySection;
