import React, { useState, useEffect } from 'react';
import styles from './Preloader.module.css';

const Preloader = ({ onLoadComplete }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prev + 1;
            });
        }, 30); // Увеличиваем на 1% каждые 30мс для симуляции загрузки

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (progress === 100) {
            onLoadComplete();
        }
    }, [progress, onLoadComplete]);

    return (
        <div className={styles.preloader}>
            <div className={styles.progressBar}>
                <div className={styles.progress} style={{ width: `${progress}%` }}></div>
            </div>
            <div className={styles.progressText}>{progress}%</div>
        </div>
    );
};

export default Preloader;
